// logo in login page
.logoInLogin {
  width: 10rem;
}

.buttonLogin {
  color: red($color: #000000);
}

// customize card header
.card-header-C {
  padding: 0.4rem 1.25rem;
  margin-bottom: 0;
  background-color: #adaa97;
  border-bottom: 1px solid #c8ced3;
}

.card-header-login {
  background-color: #ffffffff !important;
  border-bottom: 0px solid #c8ced3 !important;
}

.card-header .nav-tabs .nav-link.active {
  color: #23282c;
  background: #FFF;
  font-weight: bold;
}

// customize title in Card Header
.CardHeader-title {
  font-size: 20px;
  color: #ffffff;
}
// delete shadow of table
.MuiPaper-elevation2 {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2),
    0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12) !important;
}

.table {
  font-family: arial, sans-serif;
  border-collapse: collapse;
  width: 100%;
}

.switch {
  position: relative;
  display: inline-block;
  width: 40px;
  height: 20px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 16px;
  width: 16px;
  left: 2px;
  bottom: 2px;
  background-color: white;
  -webkit-transition: 0.2s;
  transition: 0.2s;
}

input:checked + .slider {
  background-color: #2196f3;
}

input:focus + .slider {
  box-shadow: 0 0 1px #2196f3;
}

input:checked + .slider:before {
  -webkit-transform: translateX(18px);
  -ms-transform: translateX(18px);
  transform: translateX(18px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 25px;
}

.slider.round:before {
  border-radius: 50%;
}

.img-avatar-SideBarHeader {
  width: 7rem;
  margin: 0.5rem auto 0.5rem;
}

.card-C {
  margin-bottom: 1.5rem;
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 0px solid #c8ced3 !important;
  border-radius: 0.25rem;
}

.custom-component-chart {
  padding-top: 20px;
  position: relative;
}

.custom-component-chart .date-range-pickers {
  position: absolute;
  top: 0;
  right: 0;
}

.text-small {
  font-size: 60%;
}

.text-value {
  font-size: 1rem;
  font-weight: 600;
}

.MuiTab-labelIcon {
  min-height: 0px !important;
  padding-top: 5px !important;
}

.PrivateTabScrollButton-root-256 {
  width: 0px !important;
  flex-shrink: 0;
}
.MuiPaper-elevation4 {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0), 0px 0px 0px 0px rgba(0, 0, 0, 0),
    0px 0px 0px 0px rgba(0, 0, 0, 0) !important;
}
.MuiAppBar-colorDefault {
  color: rgba(0, 0, 0, 0.87);
  background-color: #fff !important;
}

.stats-small {
  min-height: 8.7rem;
  overflow: hidden !important;
}
.stats-small canvas {
  position: absolute;
  bottom: 0;
}
.stats-small__data {
  flex: 1;
  display: flex;
  justify-content: center;
  flex-flow: column;
  max-width: 50%;
  z-index: 1;
  pointer-events:none;
}
.stats-small__label {
  font-size: 0.625rem;
  letter-spacing: 0.0625rem;
  color: #818ea3;
}
.stats-small__value {
  font-family: Roboto, -apple-system, BlinkMacSystemFont, "Segoe UI",
    "Helvetica Neue", Arial, sans-serif;
  font-size: 1.5rem;
  font-weight: 500;
}
.stats-small__percentage {
  position: relative;
  display: table;
  margin-left: auto;
  padding-left: 0.9375rem;
}
.stats-small__percentage--increase,
.stats-small__percentage--decrease {
  font-size: 0.75rem;
}
.stats-small__percentage--increase::before,
.stats-small__percentage--decrease::before {
  content: "";
  width: 0.75rem;
  height: 0.375rem;
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  background-position: center center;
  background-repeat: no-repeat;
}
.stats-small__percentage--increase {
  color: #17c671;
}
.stats-small__percentage--increase::before {
  background-image: url(data:image/svg+xml;base64,PHN2ZyBmaWxsPSIjMTdjNjcxIiBoZWlnaHQ9IjI0IiB2aWV3Qm94PSIwIDAgMjQgMjQiIHdpZHRoPSIyNCIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4gPHBhdGggZD0iTTcgMTRsNS01IDUgNXoiLz4gPHBhdGggZD0iTTAgMGgyNHYyNEgweiIgZmlsbD0ibm9uZSIvPiA8L3N2Zz4=);
}
.stats-small__percentage--decrease {
  color: #c4183c;
}
.stats-small__percentage--decrease::before {
  background-image: url(data:image/svg+xml;base64,PHN2ZyBmaWxsPSIjYzQxODNjIiBoZWlnaHQ9IjI0IiB2aWV3Qm94PSIwIDAgMjQgMjQiIHdpZHRoPSIyNCIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4gICAgPHBhdGggZD0iTTcgMTBsNSA1IDUtNXoiLz4gICAgPHBhdGggZD0iTTAgMGgyNHYyNEgweiIgZmlsbD0ibm9uZSIvPjwvc3ZnPg==);
}
.stats-small--1 .stats-small__data {
  max-width: 100%;
}
.stats-small--1 .stats-small__percentage {
  margin: 0 auto;
}
.stats-small--1 .stats-small__value {
  font-size: 2.0625rem;
}
.stats-small--1 .stats-small__label {
  font-size: 0.75rem;
}
.stats-small--1 .stats-small__percentage {
  font-size: 0.75rem;
}
.stats-small--1 canvas {
  opacity: 0.5;
}

.card-shadow {
  background-color: #fff;
  border: none;
  border-radius: 0.625rem;
  box-shadow: 0 0.46875rem 2.1875rem rgba(90, 97, 105, 0.1),
    0 0.9375rem 1.40625rem rgba(90, 97, 105, 0.1),
    0 0.25rem 0.53125rem rgba(90, 97, 105, 0.12),
    0 0.125rem 0.1875rem rgba(90, 97, 105, 0.1);
}

.card-group .stats-small {
  position: relative;
  overflow: hidden;
}

.main-content > .main-content-container.container-fluid {
  min-height: calc(100vh - 7.5rem);
}
.date-range .react-datepicker-wrapper {
  display: flex;
  flex: 1;
  max-width: 160px;
}

.date-range .react-datepicker__input-container {
  width: 100%;
}

@media (max-width: 575px) {
  .date-range .react-datepicker-wrapper {
    max-width: 100%;
  }
}

.stats-item {
  padding: 1rem;
  box-sizing: border-box !important;
  flex-direction: column !important;
  flex: 1 1 !important;
  align-items: center !important;
  display: flex !important;
}

.stats-category {
  text-align: center !important;
  color: #818ea3 !important;
  font-size: .6rem !important;
  margin-bottom: .125rem !important;
}

.stats-value {
  box-sizing: border-box !important;
  text-align: center !important;
  font-size: .925rem !important;
  color: #3d5170 !important;
  font-weight: 500 !important;
  line-height: 1rem !important;
}


.tableTitle {
  border: none;
  background: #404040;
  color: #ffffff !important;
  font-size: 15px;
  padding: 7px 40px;

  text-transform: uppercase;
  border-radius: 6px;
  display: inline-block;
  // cursor: pointer;
}

.makeStyles-root-306 .MuiTextField-root {
  margin: 0px !important;
}

.MuiInputBase-root {

  font-size: 13px !important;

}

.bg-light-error {
  background-color:   rgba(255, 0, 55, 0.6);
}
.bg-light-warning {
  background-color:   rgba(255, 180, 0, 0.6);
}
.bg-light-success {
  background-color:   rgba(23, 198, 113, 0.6);
}
.bg-light-info {
  background-color:   rgba(0, 184, 216, 0.6);
}
.bg-light-pink {
  background-color:   rgba(255, 0, 179, 0.6);
}
.bg-light-indigo {
  background-color:   rgba(216, 20, 216, 0.6);
}

.MuiTypography-body1 {
  font-size: 13px !important;

}